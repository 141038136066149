import React, { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { Icons } from 'components';
import { checkFeatureFlag, featureFlagsEnum } from 'helpers';
import { UserContext } from 'providers';

export const UploadFileDropzone = ({ files, onDrop, isSingleFileUpload }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const { featureFlags } = useContext(UserContext);

  const uploadPdf = checkFeatureFlag(featureFlags, featureFlagsEnum.PDF_UPLOAD);

  const useStyles = makeStyles({
    dropzoneStyle: {
      backgroundColor: theme.palette.gray.lighter,
      border: `1px dashed ${theme.palette.gray.light}`,
      outline: 'none',
      display: 'flex',
      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '20px',
      width: isSingleFileUpload && '450px',
      height: '180px',
      flexDirection: 'column'
    },
    maximumUploadMessage: {
      paddingTop: '20px',
      color: theme.palette.gray.main,
      fontFamily: theme.typography.fontFamilyPrimaryRegular,
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(12)
    }
  });
  const classes = useStyles();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'text/html': ['.docx', uploadPdf && '.pdf']
    },
    useFsAccessApi: false
  });

  return (
    <Grid sx={{ pr: isSingleFileUpload && '40px', pl: isSingleFileUpload && '40px' }}>
      <Grid container {...getRootProps()} className={classes.dropzoneStyle}>
        <input
          {...getInputProps({ multiple: !isSingleFileUpload })}
          disabled={isSingleFileUpload && !!files}
          data-cy='uploadDocumentsDropzone'
        />
        <div
          style={{
            height: '30px',
            width: '50px',
            marginTop: '10px'
          }}>
          <Icons iconName={'cloudUploadIcon'} fill={theme.palette.gray.main} />
        </div>
        <Box sx={{ p: 3, ml: { md: 2 } }}>
          <Typography
            variant='body2'
            sx={{
              color: theme.palette.gray.main,
              fontFamily: theme.typography.fontFamilyPrimaryRegular,
              textAlign: 'center'
            }}>
            {t('dropFiles')}
            <Typography
              variant='body2'
              component='span'
              sx={{
                color:
                  isSingleFileUpload && files ? theme.palette.gray.main : theme.palette.active.dark,
                fontFamily: theme.typography.fontFamilyPrimaryRegular,
                textDecoration: 'underline',
                cursor: 'pointer'
              }}>
              {t('browse')}
            </Typography>
            {t('chooseFileFromMachine')}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

UploadFileDropzone.propTypes = {
  files: PropTypes.array,
  onDrop: PropTypes.func,
  isSingleFileUpload: PropTypes.bool
};
