import { MESSAGE_TYPES, suggestionActionTypesEnum } from 'helpers';

export const getSuggestionText = (t, action) => {
  switch (action) {
    case suggestionActionTypesEnum.REMOVE_COMPLEX_WORD:
      return t('removeComplexWordText');
    case suggestionActionTypesEnum.BREAK_SENTENCE:
      return t('breakSentenceText');
    case suggestionActionTypesEnum.SYLLABLE_COUNT:
      return t('syllableCountText');
    case suggestionActionTypesEnum.REMOVE_CHARS:
      return t('characterCountText');
    case suggestionActionTypesEnum.REMOVE_WORD:
      return t('wordCountText');
    case suggestionActionTypesEnum.REMOVE_PASSIVE_VOICE:
      return t('removePassiveVoiceText');
    case suggestionActionTypesEnum.COMPLEX_PARAGRAPH:
      return t('complexParagraphText');
    case suggestionActionTypesEnum.REMOVE_ADVERB:
      return t('removeAdverbText');
  }
};

export const getSuggestionSubtitle = (t, action) => {
  switch (action) {
    case suggestionActionTypesEnum.REMOVE_PASSIVE_VOICE:
      return t('removePassiveVoiceSubtitle');
    case suggestionActionTypesEnum.REMOVE_COMPLEX_WORD:
      return t('breakSentenceTextSubtitle');
    case suggestionActionTypesEnum.BREAK_SENTENCE:
      return t('breakSentenceTextSubtitle');
  }
};

export const handleApplySuggestion = (
  connection,
  documentId,
  quillEditor,
  replacement,
  suggestion
) => {
  const suggRange = suggestion.suggestionRange;

  connection.invoke(
    MESSAGE_TYPES.ADD_APPLIED_REPLACEMENT,
    documentId,
    suggRange.startIndex,
    suggestion.text,
    replacement,
    suggestion.targetType
  );

  // Setting the change source to 'user' will trigger the method for editing suggestions indexes due to manual text change.
  quillEditor.deleteText(suggRange.startIndex, suggRange.endIndex - suggRange.startIndex, 'user');
  quillEditor.insertText(suggRange.startIndex, replacement, 'user');
};

export const handleApplyAdverbSuggestion = (
  connection,
  documentId,
  quillEditor,
  replacement,
  suggestion
) => {
  const suggRange = replacement.suggestionRange;

  connection.invoke(
    MESSAGE_TYPES.ADD_APPLIED_REPLACEMENT,
    documentId,
    suggRange.startIndex,
    suggestion.text,
    replacement.text,
    suggestion.targetType
  );

  quillEditor.deleteText(suggRange.startIndex, suggRange.endIndex - suggRange.startIndex, 'user');
  quillEditor.insertText(suggRange.startIndex, replacement.text, 'user');
};

export const handleRejectSuggestion = (
  connection,
  documentId,
  rejectedReplacement,
  selectedText,
  targetType,
  suggestions,
  setSuggestions
) => {
  try {
    connection.invoke(
      MESSAGE_TYPES.HIDE_REPLACEMENTS,
      documentId,
      rejectedReplacement,
      selectedText,
      targetType,
      false
    );
  } catch (e) {
    console.error(e);
  }

  setSuggestions(suggestions, rejectedReplacement);
};

// Update sentence suggestion indexes based on delta(change)
// If a change is made in the range of some suggestion, remove that suggestion
// For suggestions that come after the change in the text, update suggestions indexes
// In case of a delete operation, shift suggestions indexes left
// In case of an insert operation, shift suggestions indexes right
export const updateSuggestionsOnChange = (suggestions, delta) => {
  let changeIndex = 0;
  let newSuggestions = [...suggestions];

  delta.ops.forEach(op => {
    if (op.retain) {
      changeIndex += op.retain;
    }

    if (op.insert) {
      const insertLength = op.insert.length;

      newSuggestions = removeSuggestionOnInsert(newSuggestions, changeIndex);
      updateSuggestionIndexesOnInsert(newSuggestions, changeIndex, insertLength);

      changeIndex += insertLength;
    }

    if (op.delete) {
      newSuggestions = removeSuggestionOnDelete(newSuggestions, changeIndex, op.delete);
      updateSuggestionIndexesOnDelete(newSuggestions, changeIndex, op.delete);

      changeIndex -= op.delete;
    }
  });

  return newSuggestions;
};

export const removeSuggestionOnInsert = (sentenceSuggestions, index) => {
  return sentenceSuggestions.filter(
    ss => !(ss.suggestionRange.startIndex <= index && index <= ss.suggestionRange.endIndex)
  );
};

export const updateSuggestionIndexesOnInsert = (sentenceSuggestions, index, length) => {
  sentenceSuggestions.forEach(ss => {
    if (ss.suggestionRange.startIndex > index) {
      ss.suggestionRange.startIndex += length;
      ss.suggestionRange.endIndex += length;
    }
  });
};

export const removeSuggestionOnDelete = (sentenceSuggestions, index, length) => {
  return sentenceSuggestions.filter(
    ss => !(ss.suggestionRange.startIndex <= index + length && index <= ss.suggestionRange.endIndex)
  );
};

export const updateSuggestionIndexesOnDelete = (sentenceSuggestions, index, length) => {
  sentenceSuggestions.forEach(ss => {
    if (ss.suggestionRange.startIndex > index + length) {
      ss.suggestionRange.startIndex -= length;
      ss.suggestionRange.endIndex -= length;
    }
  });
};

export const getSuggestionCircleColor = (theme, action) => {
  let color;

  switch (action) {
    case suggestionActionTypesEnum.REMOVE_COMPLEX_WORD:
    case suggestionActionTypesEnum.REMOVE_COMPLEX_PHRASE:
      color = theme.palette.complexWord.main;
      break;

    case suggestionActionTypesEnum.BREAK_SENTENCE:
      color = theme.palette.complexSentence.main;
      break;

    case suggestionActionTypesEnum.SYLLABLE_COUNT:
    case suggestionActionTypesEnum.REMOVE_CHARS:
    case suggestionActionTypesEnum.REMOVE_WORD:
    case suggestionActionTypesEnum.REMOVE_PASSIVE_VOICE:
      color = theme.palette.clarity.main;
      break;

    case suggestionActionTypesEnum.REMOVE_ADVERB:
      color = theme.palette.writingStyle.main;
      break;

    case suggestionActionTypesEnum.REMOVE_COMPLEX_ENTITY:
      color = theme.palette.complexConcept.main;
      break;
  }

  return color;
};
