import React from 'react';

import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { IntelligibilityScoreInfo } from 'components';
import { useStyles } from 'components/DocumentEditComponent/ParagraphLevelSuggestionDialog/ParagraphLevelSuggestionDialog.css';
import { applyFormattingOnSubintervals } from 'helpers';

import { SimplificationActions } from './SimplificationActions';

export const SimplificationItemAccordion = ({
  index,
  sentenceSuggestion,
  sentenceSuggestions,
  setSentenceSuggestions,
  trackMixpanelData,
  handleApplySimplification,
  handleDialogClose,
  setIsExpanded,
  isExpanded,
  selectedText
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const {
    text,
    differences,
    simplificationId,
    suggestions,
    originalParagraphScore,
    newParagraphScore,
    improvement
  } = sentenceSuggestion;

  return (
    <Accordion
      data-testid='accordion'
      onChange={() => setIsExpanded(!isExpanded)}
      sx={{
        boxShadow: 'none',
        width: '100%',
        '&.Mui-expanded': {
          mt: 0,
          mb: '15px',
          pb: sentenceSuggestions.length - 1 === index && '15px',
          borderTop: index !== 0 && `1px solid ${theme.palette.gray.light}`
        }
      }}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={
          <IconButton
            disableRipple
            sx={{ width: '32px', height: '32px', backgroundColor: theme.palette.gray.lightest }}>
            <FontAwesomeIcon icon={faChevronDown} style={{ width: '13px', height: '20px' }} />
          </IconButton>
        }>
        <Typography className={classes.simplifications}>
          {isExpanded ? applyFormattingOnSubintervals(theme, differences, suggestions, text) : text}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <IntelligibilityScoreInfo
          originalParagraphScore={originalParagraphScore}
          newParagraphScore={newParagraphScore}
          improvement={improvement}
        />
        <SimplificationActions
          suggestionText={text}
          simplificationId={simplificationId}
          handleApplySuggestion={handleApplySimplification}
          handleDialogClose={handleDialogClose}
          sentenceSuggestions={sentenceSuggestions}
          setSentenceSuggestions={setSentenceSuggestions}
          trackMixpanelData={trackMixpanelData}
          selectedText={selectedText}
        />
      </AccordionDetails>
    </Accordion>
  );
};

SimplificationItemAccordion.propTypes = {
  index: PropTypes.number,
  sentenceSuggestion: PropTypes.object,
  sentenceSuggestions: PropTypes.array,
  setSentenceSuggestions: PropTypes.func,
  trackMixpanelData: PropTypes.func,
  handleApplySimplification: PropTypes.func,
  handleDialogClose: PropTypes.func,
  setIsExpanded: PropTypes.func,
  isExpanded: PropTypes.bool,
  selectedText: PropTypes.string
};
