import React, { createContext, useMemo, useRef, useState } from 'react';

import ApexCharts from 'apexcharts';
import PropTypes from 'prop-types';

import { scoreTypes, typesOfDocuments } from 'helpers';

export const ReportingAndAnalyticsContext = createContext();

export const ReportingAndAnalyticsProvider = ({ children }) => {
  const intelligibilityReportCharts = useRef({
    intelligibilityByLevelAllDocsStartingScore: '',
    intelligibilityByLevelAllDocsCurrentScore: '',
    intelligibilityByLevelEditedDocsStartingScore: '',
    intelligibilityByLevelEditedDocsCurrentScore: '',
    intelligibilityImprovementByLevelAll: '',
    intelligibilityImprovementByLevelEdited: ''
  });

  const riskReportCharts = useRef({
    allDocumentsCurrentScore: '',
    editedDocumentsCurrentScore: '',
    uneditedDocumentsCurrentScore: '',
    highRiskDocumentLabels: ''
  });

  const [isDonutChartAnimationDone, setIsDonutChartAnimationDone] = useState(false);
  const [isLineChartAnimationDone, setIsLineChartAnimationDone] = useState(false);
  // Risk page column charts
  const [isTimeChartAnimationDone, setIsTimeChartAnimationDone] = useState(false);
  const [isLabelsChartAnimationDone, setIsLabelsChartAnimationDone] = useState(false);

  const generateIntelligibilityReportCharts = async () => {
    const updateDonutChartUri = async (documentsType, scoreType, propertyToUpdate) => {
      const { imgURI } = await ApexCharts.exec(`chart-${documentsType}-${scoreType}`, 'dataURI');
      intelligibilityReportCharts.current[propertyToUpdate] = imgURI;
    };

    const updateLineChartDataUri = async (documentsType, propertyToUpdate) => {
      const { imgURI } = await ApexCharts.exec(`chart-${documentsType}`, 'dataURI');
      intelligibilityReportCharts.current[propertyToUpdate] = imgURI;
    };

    await Promise.all([
      updateDonutChartUri(
        typesOfDocuments.ALL_DOCUMENTS,
        scoreTypes.STARTING_SCORE,
        'intelligibilityByLevelAllDocsStartingScore'
      ),
      updateDonutChartUri(
        typesOfDocuments.ALL_DOCUMENTS,
        scoreTypes.CURRENT_SCORE,
        'intelligibilityByLevelAllDocsCurrentScore'
      ),
      updateDonutChartUri(
        typesOfDocuments.EDITED_DOCUMENTS,
        scoreTypes.STARTING_SCORE,
        'intelligibilityByLevelEditedDocsStartingScore'
      ),
      updateDonutChartUri(
        typesOfDocuments.EDITED_DOCUMENTS,
        scoreTypes.CURRENT_SCORE,
        'intelligibilityByLevelEditedDocsCurrentScore'
      ),
      updateLineChartDataUri(
        typesOfDocuments.ALL_DOCUMENTS,
        'intelligibilityImprovementByLevelAll'
      ),
      updateLineChartDataUri(
        typesOfDocuments.EDITED_DOCUMENTS,
        'intelligibilityImprovementByLevelEdited'
      )
    ]);
  };

  const generateRiskReportCharts = async () => {
    const updateColumnChartDataUri = async (documentsType, scoreType, propertyToUpdate) => {
      const { imgURI } = await ApexCharts.exec(`chart-${documentsType}-${scoreType}`, 'dataURI');
      riskReportCharts.current[propertyToUpdate] = imgURI;
    };

    const updateLabelsColumnChartDataUri = async propertyToUpdate => {
      const { imgURI } = await ApexCharts.exec('highRiskDocumentLabels', 'dataURI');
      riskReportCharts.current[propertyToUpdate] = imgURI;
    };

    await Promise.all([
      updateColumnChartDataUri(
        typesOfDocuments.ALL_DOCUMENTS,
        scoreTypes.CURRENT_SCORE,
        'allDocumentsCurrentScore'
      ),
      updateColumnChartDataUri(
        typesOfDocuments.EDITED_DOCUMENTS,
        scoreTypes.CURRENT_SCORE,
        'editedDocumentsCurrentScore'
      ),
      updateColumnChartDataUri(
        typesOfDocuments.UNEDITED_DOCUMENTS,
        scoreTypes.CURRENT_SCORE,
        'uneditedDocumentsCurrentScore'
      ),
      updateLabelsColumnChartDataUri('highRiskDocumentLabels')
    ]);
  };

  const reportingAndAnalyticsProviderValues = useMemo(
    () => ({
      intelligibilityReportCharts,
      riskReportCharts,
      generateIntelligibilityReportCharts,
      generateRiskReportCharts,
      isDonutChartAnimationDone,
      setIsDonutChartAnimationDone,
      isLineChartAnimationDone,
      setIsLineChartAnimationDone,
      isTimeChartAnimationDone,
      setIsTimeChartAnimationDone,
      isLabelsChartAnimationDone,
      setIsLabelsChartAnimationDone
    }),
    [
      intelligibilityReportCharts,
      riskReportCharts,
      generateIntelligibilityReportCharts,
      generateRiskReportCharts,
      isDonutChartAnimationDone,
      setIsDonutChartAnimationDone,
      isLineChartAnimationDone,
      setIsLineChartAnimationDone,
      isTimeChartAnimationDone,
      setIsTimeChartAnimationDone,
      isLabelsChartAnimationDone,
      setIsLabelsChartAnimationDone
    ]
  );

  return (
    <ReportingAndAnalyticsContext.Provider value={reportingAndAnalyticsProviderValues}>
      {children}
    </ReportingAndAnalyticsContext.Provider>
  );
};

ReportingAndAnalyticsProvider.propTypes = {
  children: PropTypes.any
};
