import Quill from 'quill';

const Inline = Quill.import('blots/inline');

export class ComplexSentenceSimplificationBlot extends Inline {
  static blotName = 'complex_sentence_simplification';

  static tagName = 'complex_sentence_simplification';

  static create(value) {
    const node = super.create();
    if (value.underliningType) {
      node.classList.add('complex_sentence_simplification');
    }
    if (value.active) {
      node.classList.add('complex_sentence_simplification_active');
    }

    return node;
  }
}

export class ComplexWordSimplificationBlot extends Inline {
  static blotName = 'complex_word_simplification';

  static tagName = 'complex_word_simplification';

  static create(value) {
    const node = super.create();
    if (value.underliningType && !value.isRephraseSelected) {
      node.classList.add(value.underliningType);
    }
    if (value.active) {
      node.classList.add('complex_word_simplification_active');
    }

    return node;
  }
}

export class WritingStyleSimplificationBlot extends Inline {
  static blotName = 'writing_style_simplification';

  static tagName = 'writing_style_simplification';

  static create(value) {
    const node = super.create();
    if (value.underliningType && !value.isRephraseSelected) {
      node.classList.add(value.underliningType);
    }
    if (value.active) {
      node.classList.add('writing_style_simplification_active');
    }

    return node;
  }
}

export class ClaritySimplificationBlot extends Inline {
  static blotName = 'clarity_simplification';

  static tagName = 'clarity_simplification';

  static create(value) {
    const node = super.create();
    if (value.underliningType && !value.isRephraseSelected) {
      node.classList.add(value.underliningType);
    }
    if (value.active) {
      node.classList.add('clarity_simplification_active');
    }

    return node;
  }
}

export class ComplexConceptSimplificationBlot extends Inline {
  static blotName = 'complex_concept_simplification';

  static tagName = 'complex_concept_simplification';

  static create(value) {
    const node = super.create();
    if (value.underliningType && !value.isRephraseSelected) {
      node.classList.add(value.underliningType);
    }
    if (value.active) {
      node.classList.add('complex_concept_simplification_active');
    }

    return node;
  }
}

Inline.order.splice(Inline.order.indexOf('script'), 0, 'complex_word_simplification');
Inline.order.splice(Inline.order.indexOf('script'), 0, 'writing_style_simplification');
Inline.order.splice(Inline.order.indexOf('script'), 0, 'clarity_simplification');
Inline.order.splice(Inline.order.indexOf('script'), 0, 'complex_concept_simplification');
Inline.order.splice(Inline.order.indexOf('script'), 0, 'complex_sentence_simplification');

Quill.register('formats/complex_sentence_simplification', ComplexSentenceSimplificationBlot);
Quill.register('formats/complex_word_simplification', ComplexWordSimplificationBlot);
Quill.register('formats/writing_style_simplification', WritingStyleSimplificationBlot);
Quill.register('formats/clarity_simplification', ClaritySimplificationBlot);
Quill.register('formats/complex_concept_simplification', ComplexConceptSimplificationBlot);
