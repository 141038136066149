import { suggestionActionTypesEnum, suggestionCategories } from 'helpers';

export const getSuggestionMappings = theme => ({
  [suggestionActionTypesEnum.BREAK_SENTENCE]: {
    category: 'complexSentence',
    hint: 'complexSentence',
    description: 'complexSentenceDescription',
    maxWidth: '120px',
    buttonText: 'simplifySentence',
    format: 'complex_sentence_simplification',
    color: theme.palette.complexSentence?.main,
    suggestedAction: 'simplifySentence'
  },
  [suggestionActionTypesEnum.REMOVE_COMPLEX_WORD]: {
    category: 'complexWord',
    hint: 'complexWord',
    description: 'complexWordDescription',
    maxWidth: '120px',
    highlightColor: theme.palette.complexWord?.light,
    buttonText: 'simplifyWord',
    format: 'complex_word_simplification',
    color: theme.palette.complexWord?.main,
    suggestedAction: 'simplifyWord'
  },
  [suggestionActionTypesEnum.REMOVE_COMPLEX_PHRASE]: {
    category: 'complexPhrase',
    hint: 'complexPhrase',
    description: 'complexPhraseDescription',
    maxWidth: '120px',
    highlightColor: theme.palette.complexWord?.light,
    buttonText: 'simplifyPhrase',
    format: 'complex_word_simplification',
    color: theme.palette.complexWord?.main,
    suggestedAction: 'simplifyPhrase'
  },
  [suggestionActionTypesEnum.REMOVE_ADVERB]: {
    category: 'writingStyle',
    hint: 'adverb',
    description: 'writingStyleDescription',
    maxWidth: '160px',
    highlightColor: theme.palette.writingStyle?.light,
    buttonText: 'rephraseSentence',
    format: 'writing_style_simplification',
    color: theme.palette.writingStyle?.main,
    suggestedAction: 'replaceAdverb'
  },
  [suggestionActionTypesEnum.SYLLABLE_COUNT]: {
    category: 'clarity',
    hint: 'longWord',
    description: 'longWordDescription',
    maxWidth: '120px',
    highlightColor: theme.palette.clarity?.light,
    buttonText: 'shortenWord',
    format: 'clarity_simplification',
    color: theme.palette.clarity?.main,
    suggestedAction: 'shortenWord'
  },
  [suggestionActionTypesEnum.REMOVE_CHARS]: {
    category: 'clarity',
    hint: 'longWord',
    description: 'longWordDescription',
    maxWidth: '120px',
    highlightColor: theme.palette.clarity?.light,
    buttonText: 'shortenWord',
    format: 'clarity_simplification',
    color: theme.palette.clarity?.main,
    suggestedAction: 'shortenWord'
  },
  [suggestionActionTypesEnum.REMOVE_WORD]: {
    category: 'clarity',
    hint: 'longWord',
    description: 'longWordDescription',
    maxWidth: '120px',
    highlightColor: theme.palette.clarity?.light,
    buttonText: 'shortenWord',
    format: 'clarity_simplification',
    color: theme.palette.clarity?.main,
    suggestedAction: 'shortenWord'
  },
  [suggestionActionTypesEnum.LONG_SENTENCE]: {
    category: 'clarity',
    hint: 'longSentence',
    description: 'longSentenceDescription',
    maxWidth: '120px',
    highlightColor: theme.palette.clarity?.light,
    buttonText: 'shortenSentence',
    format: 'clarity_simplification',
    color: theme.palette.clarity?.main,
    suggestedAction: 'shortenSentence'
  },
  [suggestionActionTypesEnum.REMOVE_PASSIVE_VOICE]: {
    category: 'clarity',
    hint: 'passiveVoice',
    description: 'passiveVoiceDescription',
    maxWidth: '120px',
    highlightColor: theme.palette.clarity?.light,
    buttonText: 'rewriteSentence',
    format: 'clarity_simplification',
    color: theme.palette.clarity?.main,
    suggestedAction: 'correctPassiveVoice'
  },
  [suggestionActionTypesEnum.REMOVE_COMPLEX_ENTITY]: {
    category: 'complexConcept',
    hint: 'complexConcept',
    description: 'complexConceptDescription',
    maxWidth: '85px',
    highlightColor: theme.palette.complexConcept?.light,
    buttonText: 'dismiss',
    format: 'complex_concept_simplification',
    color: theme.palette.complexConcept?.main,
    suggestedAction: 'requiresExplanation'
  }
});

export const actionTypesPerCategory = {
  [suggestionCategories.COMPLEX_SENTENCE]: [suggestionActionTypesEnum.BREAK_SENTENCE],
  [suggestionCategories.COMPLEX_PHRASE]: [
    suggestionActionTypesEnum.REMOVE_COMPLEX_WORD,
    suggestionActionTypesEnum.REMOVE_COMPLEX_PHRASE
  ],
  [suggestionCategories.WRITING_STYLE]: [suggestionActionTypesEnum.REMOVE_ADVERB],
  [suggestionCategories.CLARITY]: [
    suggestionActionTypesEnum.SYLLABLE_COUNT,
    suggestionActionTypesEnum.REMOVE_CHARS,
    suggestionActionTypesEnum.REMOVE_WORD,
    suggestionActionTypesEnum.REMOVE_PASSIVE_VOICE,
    suggestionActionTypesEnum.LONG_SENTENCE
  ],
  [suggestionCategories.COMPLEX_CONCEPT]: [suggestionActionTypesEnum.REMOVE_COMPLEX_ENTITY]
};

export const suggestionFormats = [
  'complex_sentence_simplification',
  'complex_word_simplification',
  'writing_style_simplification',
  'clarity_simplification',
  'complex_concept_simplification'
];

export const categoryFormat = {
  [suggestionCategories.COMPLEX_SENTENCE]: 'complex_sentence_simplification',
  [suggestionCategories.COMPLEX_PHRASE]: 'complex_word_simplification',
  [suggestionCategories.WRITING_STYLE]: 'writing_style_simplification',
  [suggestionCategories.CLARITY]: 'clarity_simplification',
  [suggestionCategories.COMPLEX_CONCEPT]: 'complex_concept_simplification'
};

export const activeSuggestionHighlights = [
  'complex_sentence_simplification_active',
  'complex_word_simplification_active',
  'writing_style_simplification_active',
  'clarity_simplification_active',
  'complex_concept_simplification_active'
];
