import React, { useContext, useEffect, useState } from 'react';

import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';

import { mixpanel, scrollToOverflowingDialog, MESSAGE_TYPES } from 'helpers';
import { DocumentStateContext } from 'providers';
import { useStoreFields } from 'stores';

import { SimplificationItemAccordion } from './SimplificationItemAccordion';

export const SimplificationItem = ({
  index,
  sentenceSuggestion,
  sentenceSuggestions,
  setSentenceSuggestions,
  quillEditor,
  dialogRef,
  scrollRef,
  startIndex,
  endIndex,
  selectedText,
  setSelectedText,
  suggestion,
  setExpandedAccordion
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { connection, resetAutosaveTimer } = useContext(DocumentStateContext);

  const { setSelectedSimplificationCard, setSelectedSuggestionIds } = useStoreFields([
    'setSelectedSimplificationCard',
    'setSelectedSuggestionIds'
  ]);

  const { documentId, documentSuggestions, setDocumentSuggestions, setIsParagraphDialogOpen } =
    useStoreFields([
      'documentId',
      'documentSuggestions',
      'setDocumentSuggestions',
      'setIsParagraphDialogOpen'
    ]);

  const trackMixpanelData = (hide, suggestionText) => {
    mixpanel.track('Use selected text suggestion', {
      Hide: hide ? 'On' : 'Off',
      'Replacement used': suggestionText,
      'Selected text': selectedText
    });
  };

  const handleApplySimplification = simplificationId => {
    connection.invoke(
      MESSAGE_TYPES.APPLY_TEXT_SIMPLIFICATION,
      documentId,
      startIndex,
      selectedText,
      simplificationId
    );

    quillEditor.deleteText(startIndex, endIndex - startIndex, 'user');
    quillEditor.insertText(startIndex, sentenceSuggestion.text, 'user');

    if (suggestion) {
      const filteredSuggestions = documentSuggestions?.filter(
        item => item.suggestionId !== suggestion.suggestionId
      );
      setDocumentSuggestions(filteredSuggestions);
      setSelectedSuggestionIds([]);
      setExpandedAccordion(null);
      setSelectedSimplificationCard(null);
    }

    resetAutosaveTimer();
    setSelectedText(null);
    setIsParagraphDialogOpen(false);
  };

  const handleDialogClose = () => {
    quillEditor?.formatText(startIndex, endIndex - startIndex, 'paragraph_suggestion', false);

    connection?.invoke(MESSAGE_TYPES.CLOSE_SIMPLIFICATIONS);
  };

  useEffect(() => {
    // Dialog gets cut-off when it contains less than 3 suggestions and when it's opened near the end of the page with accordions expanded
    // SetTimeout is used because it takes some time for accordions to finish their expansion and that's when the dialog height and position should change
    // Whenever accordion gets expanded, scrollToOverflowingDialog method is called to reposition the dialog
    setTimeout(() => {
      if (dialogRef?.current) {
        scrollToOverflowingDialog(dialogRef.current, scrollRef);
      }
    }, 200);
  }, [isExpanded]);

  return (
    <SimplificationItemAccordion
      index={index}
      sentenceSuggestion={sentenceSuggestion}
      sentenceSuggestions={sentenceSuggestions}
      setSentenceSuggestions={setSentenceSuggestions}
      trackMixpanelData={trackMixpanelData}
      handleApplySimplification={handleApplySimplification}
      handleDialogClose={handleDialogClose}
      setIsExpanded={setIsExpanded}
      isExpanded={isExpanded}
      selectedText={selectedText}
    />
  );
};

SimplificationItem.propTypes = {
  index: PropTypes.number,
  sentenceSuggestion: PropTypes.object,
  sentenceSuggestions: PropTypes.array,
  setSentenceSuggestions: PropTypes.func,
  quillEditor: PropTypes.object,
  dialogRef: PropTypes.object,
  scrollRef: PropTypes.object,
  startIndex: PropTypes.number,
  endIndex: PropTypes.number,
  selectedText: PropTypes.string,
  setSelectedText: PropTypes.func,
  suggestion: PropTypes.object,
  setExpandedAccordion: PropTypes.func
};
