import React, { useEffect, createContext, useContext, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as signalR from '@microsoft/signalr';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { checkRequestLoggingToConsole, MESSAGE_TYPES, toastMessage, getApiCoreUrl } from 'helpers';
import { getCurrentTenantId, UserContext } from 'providers';
import { tokenService } from 'services';

export const WebSocketConnectionContext = createContext({
  connection: null,
  sections: null,
  overallReadabilityData: null,
  isFetchingReadabilityInfo: false,
  isFetchingReplacements: false,
  isFetchingSuggestions: false,
  isSaveInProgress: false,
  isFetchingDocumentSuggestions: false,
  isFetchingSelectedTextSimplifications: false
});

export const WebSocketConnectionProvider = ({ id, children }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [t] = useTranslation('common');
  const [tError] = useTranslation('error');
  const [connection, setConnection] = useState(null);
  const [sections, setSections] = useState(null);
  const [sectionsInfo, setSectionsInfo] = useState(null);
  const [overallReadabilityData, setOverallReadabilityData] = useState(null);
  const [isFetchingReadabilityInfo, setIsFetchingReadabilityInfo] = useState(false);
  const [isFetchingReplacements, setIsFetchingReplacements] = useState(false);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);
  const [isFetchingSelectedTextSimplifications, setIsFetchingSelectedTextSimplifications] =
    useState(false);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [currentViewers, setCurrentViewers] = useState([]);
  const [previouslyClickedSection, setPreviouslyClickedSection] = useState();
  const [history, setHistory] = useState({ undo: [], redo: [] });
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [isParagraphDialogOpen, setIsParagraphDialogOpen] = useState(false);
  const [isEditingDisabled, setIsEditingDisabled] = useState(false);
  const [documentSuggestions, setDocumentSuggestions] = useState([]);
  const [isFetchingDocumentSuggestions, setIsFetchingDocumentSuggestions] = useState(true);
  const [isSuggestionReloadInProgress, setIsSuggestionReloadInProgress] = useState(false);

  const internalRequestIdRef = useRef();
  const reconnectionAttemptsLimitReached = useRef();
  const connectionRef = useRef();
  connectionRef.current = connection;
  const saveIntervalRef = useRef();
  const baseUrl = getApiCoreUrl();
  const { user: currentAppUser } = useContext(UserContext);

  const cleanup = () => {
    clearInterval(saveIntervalRef.current);
    connectionRef.current?.stop();
  };

  const isMLDataPresent = () => {
    return overallReadabilityData && !overallReadabilityData.mlDataNotPresent;
  };

  const joinGroup = (connection, isReconnection = false) => {
    connection
      .invoke(MESSAGE_TYPES.JOIN_GROUP, Number(id), currentAppUser.id, isReconnection)
      .then(resp => {
        setSections(resp.deltaQuill);

        if (isReconnection) {
          setIsReconnecting(false);
          toastMessage(enqueueSnackbar, closeSnackbar, t('reconnected'), 'success');
        }

        checkRequestLoggingToConsole(currentAppUser.permissions, resp.requestIM) &&
          // eslint-disable-next-line no-console
          console.log(
            `Requested opening of document ${id}. ${resp.requestIM.requestType} request ${resp.requestIM.requestId} has been sent. Request cacheId is ${resp.requestIM.cacheId}. Model version is ${resp.requestIM.modelVersion}.`
          );
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    currentAppUser &&
      tokenService.getToken().then(token => {
        const currentTenantId = getCurrentTenantId();
        const conn = new signalR.HubConnectionBuilder()
          .withUrl(
            `${baseUrl}/suggestionDensityHub?access_token=${token}&&document_id=${id}&&tenant_id=${currentTenantId}`
          )
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: retryContext => {
              if (retryContext.previousRetryCount > 100) {
                reconnectionAttemptsLimitReached.current = true;
                return null;
              }
              if (retryContext.previousRetryCount < 5) {
                retryContext.previousRetryCount += 1;
                return retryContext.previousRetryCount * 1000;
              }
              return 15000;
            }
          })
          .build();

        conn.serverTimeoutInMilliseconds = 3000;
        conn.keepAliveIntervalInMilliseconds = 1000;

        conn.onreconnecting(() => {
          setIsReconnecting(true);
          toastMessage(enqueueSnackbar, closeSnackbar, t('reconnecting'), 'warning');
        });

        conn.onreconnected(() => {
          joinGroup(conn, true);
        });

        setConnection(conn);

        conn.on(MESSAGE_TYPES.RECEIVE_CURRENT_VIEWERS_INFO, currentViewers => {
          setCurrentViewers(currentViewers);
        });

        conn.on(MESSAGE_TYPES.RECEIVE_DISABLE_EDITING_MESSAGE, resp => {
          toastMessage(
            enqueueSnackbar,
            closeSnackbar,
            t('editingDisabled', { username: resp }),
            'warning'
          );
          setIsEditingDisabled(true);
          setSectionsInfo(null);
        });

        conn.on(MESSAGE_TYPES.RECEIVE_ENABLE_EDITING_MESSAGE, () => {
          toastMessage(enqueueSnackbar, closeSnackbar, t('editingEnabled'), 'success');
          setIsEditingDisabled(false);
        });

        conn.on(MESSAGE_TYPES.RECEIVE_VERSION_INTELLIGIBILITY, (resp, internalId) => {
          if (!internalId || internalId === internalRequestIdRef.current) {
            setSections(resp);
          }
        });

        conn.on(MESSAGE_TYPES.RECEIVE_INTELLIGIBILITY_INFO, resp => {
          setOverallReadabilityData(resp);
          setIsSaveInProgress(false);
          setIsFetchingReadabilityInfo(false);
        });

        conn.on(MESSAGE_TYPES.RECEIVE_DOCUMENT_INFO, documentInfo => {
          setSectionsInfo(documentInfo.paragraphs);
        });

        const start = async () => {
          try {
            await conn.start();
            joinGroup(conn);
            if (reconnectionAttemptsLimitReached.current) {
              setIsReconnecting(false);
              toastMessage(enqueueSnackbar, closeSnackbar, t('editingEnabled'), 'success');
              reconnectionAttemptsLimitReached.current = false;
            }
          } catch (error) {
            if (error.message.includes('403')) {
              toastMessage(enqueueSnackbar, closeSnackbar, t('nonExistingDocument'), 'warning');
              return navigate('/documents');
            }
            if (reconnectionAttemptsLimitReached.current) {
              setIsReconnecting(true);
              toastMessage(enqueueSnackbar, closeSnackbar, t('reconnecting'), 'warning');
            } else {
              toastMessage(enqueueSnackbar, closeSnackbar, tError('ForbiddenDocument'), 'error');
            }
            setTimeout(start, 5000);
          }
        };

        conn.onclose(async () => {
          reconnectionAttemptsLimitReached.current && (await start());
        });

        start();
      });

    return () => {
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const connectionProviderValues = useMemo(
    () => ({
      connection,
      setConnection,
      sections,
      sectionsInfo,
      setSections,
      overallReadabilityData,
      setOverallReadabilityData,
      isFetchingReadabilityInfo,
      isFetchingReplacements,
      setIsFetchingReplacements,
      isFetchingSuggestions,
      setIsFetchingSuggestions,
      isSaveInProgress,
      id,
      setIsSaveInProgress,
      internalRequestIdRef,
      saveIntervalRef,
      cleanup,
      isMLDataPresent,
      currentViewers,
      previouslyClickedSection,
      setPreviouslyClickedSection,
      history,
      setHistory,
      isReconnecting,
      isParagraphDialogOpen,
      setIsParagraphDialogOpen,
      isEditingDisabled,
      documentSuggestions,
      setDocumentSuggestions,
      isFetchingDocumentSuggestions,
      setIsFetchingDocumentSuggestions,
      isFetchingSelectedTextSimplifications,
      setIsFetchingSelectedTextSimplifications,
      isSuggestionReloadInProgress,
      setIsSuggestionReloadInProgress
    }),
    [
      connection,
      setConnection,
      sections,
      setSections,
      overallReadabilityData,
      setOverallReadabilityData,
      isFetchingReadabilityInfo,
      isFetchingReplacements,
      setIsFetchingReplacements,
      isFetchingSuggestions,
      setIsFetchingSuggestions,
      isSaveInProgress,
      id,
      setIsSaveInProgress,
      internalRequestIdRef,
      saveIntervalRef,
      cleanup,
      isMLDataPresent,
      currentViewers,
      previouslyClickedSection,
      setPreviouslyClickedSection,
      history,
      setHistory,
      isReconnecting,
      isParagraphDialogOpen,
      setIsParagraphDialogOpen,
      isEditingDisabled,
      documentSuggestions,
      setDocumentSuggestions,
      isFetchingDocumentSuggestions,
      setIsFetchingDocumentSuggestions,
      isFetchingSelectedTextSimplifications,
      setIsFetchingSelectedTextSimplifications,
      isSuggestionReloadInProgress,
      setIsSuggestionReloadInProgress
    ]
  );

  return (
    <WebSocketConnectionContext.Provider value={connectionProviderValues}>
      {children}
    </WebSocketConnectionContext.Provider>
  );
};

WebSocketConnectionProvider.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string
};
