import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { useLocation } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { setTableBindings } from 'components/Table/Table';
import {
  calculateIndices,
  debounce,
  displayBorder,
  MESSAGE_TYPES,
  removePreviousSelectionWhileAddingComments,
  scrollToElementIfNeeded,
  toastMessage,
  stringifyCommentValues
} from 'helpers';
import { useCollapseDrawer } from 'hooks';
import { WebSocketConnectionContext } from 'providers';

import './CommentAttribute.js';
import { setEditorListBindings, setNumberingAndStyle, setSelectedListBtn } from './CustomList.js';
import { useStyles } from './DocumentEditComponent.css.js';
import './HeadingAttribute.js';
import './HighlightCommentAttribute.js';
import {
  checkIfBoldIsActiveOnNewListItemLine,
  findEditsPositionFromText,
  getSectionDimensionData,
  scrollToNewlyInsertedElement,
  removeComplexityHighlight
} from './methods.js';
import { ParagraphLevelSuggestionDialog } from './ParagraphLevelSuggestionDialog/ParagraphLevelSuggestionDialog.js';
import './QuillClipboard.js';
import { formats } from './QuillFormats';
import './ReadabilityLevelBlots.js';
import './SuggestionBlots.js';
import { modules } from '../../containers/DocumentEditPage/CustomInlineToolbar/CustomInlineToolbar.js';

export const DocumentEditComponent = ({
  deltaChanges,
  handleSectionsDimensionData,
  resetTimers,
  activeIndex,
  search,
  getParentElement,
  showComplexityMap,
  selection,
  quillRef,
  getClickedSentenceRange,
  removeSuggestionsDueToManualTextChange,
  setActiveHighlightIndex,
  scrollRef,
  clickedIndicatorIndex,
  setClickedIndicatorIndex,
  sectionsDimensionData,
  isFetchingSuggestions,
  sentenceSuggestions,
  setSentenceSuggestions,
  dialogRef,
  isCommentingActive,
  commentSelection,
  setCommentSelection,
  selectionDelta,
  isCommentTabSelected,
  comments,
  commentId,
  resultsIndices
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { pathname } = useLocation();

  const quillEditor = quillRef.current?.getEditor();
  const scrollTop = scrollRef.current?.scrollTop;

  const {
    connection,
    sections,
    id: documentId,
    setHistory,
    isReconnecting,
    isEditingDisabled
  } = useContext(WebSocketConnectionContext);

  let changeTimeout;
  let textChanged = false;
  const complexityFormats = ['medium_priority', 'high_priority'];

  const [textEditPosition, setTextEditPosition] = useState({});
  const [currentHighlight, setCurrentHighlight] = useState(null);

  const previousSearch = useRef('');

  const { isCollapse, onOpenEditorCollapse } = useCollapseDrawer();
  const isMobile = useMediaQuery(theme.breakpoints.down(displayBorder.small));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [t] = useTranslation('common');

  useEffect(() => {
    changeTimeout = setTimeout(changeIsEdited, 100);
    window.addEventListener('resize', setIndicatorsAfterSidebarChange);
    return () => {
      window.removeEventListener('resize', setIndicatorsAfterSidebarChange);
    };
  }, []);

  const parseCommentValues = sections => {
    const sectionsCopy = cloneDeep(sections);
    const deltaChangesWithParsedCommentAttribute = sectionsCopy.ops.map(op => {
      if (op?.attributes?.comment && typeof op.attributes.comment === 'string') {
        op.attributes.comment = JSON.parse(op.attributes.comment);
      }
      return op;
    });
    return { ops: deltaChangesWithParsedCommentAttribute };
  };

  const formattedSections = parseCommentValues(sections);

  // This is a temporary fix
  useEffect(() => {
    if (pathname.includes('documents')) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = '/styles/quill-override.css';
      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link);
      };
    }
  }, []);

  useEffect(() => {
    quillEditor && setEditorListBindings(quillEditor);
    quillEditor && setTableBindings(quillEditor);
    quillEditor?.setContents(formattedSections);
    quillEditor?.history.clear();
    quillEditor && !isMobile && onOpenEditorCollapse();
    sections && setNumberingAndStyle(document.getElementsByTagName('list-item'));
    // Increasing timeout interval because initial sidebar collapsing takes more than 1s
    setIndicatorsAfterSidebarChange(3000);

    connection.on(MESSAGE_TYPES.RECEIVE_CHANGES, changes => {
      const formattedChanges = parseCommentValues(changes);
      quillEditor.updateContents(formattedChanges);
      removeSuggestionsDueToManualTextChange(null);
      getSectionDimensionData(handleSectionsDimensionData, setNumberingAndStyle);
    });
  }, [quillEditor]);

  // Saving editor state from the heat map page when switching to the suggestions page
  useEffect(() => {
    // Change logic for handling sections state, this is just temporary
    // Keeping current editor state while switching between basic and complexity map view.
    // When moving complexity map -> basic view, remove highlighting for complex sentences.
    if (!showComplexityMap) {
      quillEditor?.formatText(0, quillEditor?.getText().length, 'medium_priority', false);
      quillEditor?.formatText(0, quillEditor?.getText().length, 'high_priority', false);
    }

    getClickedSentenceRange();
  }, [showComplexityMap]);

  const checkNodeStyling = node => {
    return scrollElement(node) ? node : findActualScrollingContainer(node.parentNode);
  };

  const style = (nodeElem, prop) => window.getComputedStyle(nodeElem, null).getPropertyValue(prop);
  const scrollElement = nodeElem =>
    /(scroll)/.test(
      style(nodeElem, 'overflow') + style(nodeElem, 'overflow-y') + style(nodeElem, 'overflow-x')
    );

  const findActualScrollingContainer = node => {
    return !node || node === document.body ? document.body : checkNodeStyling(node);
  };

  const editedWords = useMemo(() => {
    return findEditsPositionFromText(formattedSections);
  }, [sections]);

  useEffect(() => {
    if (quillEditor) {
      quillEditor.scrollingContainer = findActualScrollingContainer(quillEditor.container);
      selection.current && setSelectedListBtn(quillEditor, selection.current);
    }
  });

  const checkIfLastEditPositionIsVisible = () => {
    if (scrollTop > textEditPosition) {
      quillEditor?.blur();
    } else {
      try {
        selection?.current && quillEditor?.setSelection(selection.current);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  useEffect(() => {
    // setContents() set selection to 0, and triggers onSelectionChange function(which overrides selection state)
    // it's necessary to save the selection value, before setting new content, to keep it after the setting content
    const previousSelection = selection.current;
    quillEditor?.setContents(formattedSections);

    try {
      previousSelection !== null &&
        previousSelection?.index !== -1 &&
        quillEditor?.setSelection(previousSelection.index, previousSelection.length);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    // clearing history after setting the content
    quillEditor?.history.clear();
    setHistory({ undo: [], redo: [] });
    checkIfLastEditPositionIsVisible();
    textChanged = false;
    sections && setNumberingAndStyle(document.getElementsByTagName('list-item'));
  }, [sections]);

  const setIndicatorsAfterSidebarChange = (interval = 1000) => {
    setTimeout(() => {
      getSectionDimensionData(handleSectionsDimensionData, setNumberingAndStyle);
    }, interval);
  };

  useEffect(() => {
    setIndicatorsAfterSidebarChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapse]);

  useEffect(() => {
    searchText(previousSearch.current, true);
    previousSearch.current = search;
    searchText(search, false);
    setCurrentHighlight(null);
  }, [search, sections]);

  const searchText = (text, remove) => {
    setActiveHighlightIndex(0);
    const documentText = quillEditor?.getText();
    const indices = calculateIndices(documentText, text);
    resultsIndices.current = indices;
    if (indices.length > 0) {
      highlightSearchedWords(indices, text.length, remove);
    }
  };

  const highlightSearchedWords = (indices, wordLength, remove) => {
    if (search !== '') {
      const notSearchedEdits = editedWords.filter(word => !word.text.includes(search));
      if (editedWords.length !== notSearchedEdits.length) {
        //When edited word is searched, highlight format isn't shown unless insertedText format is removed
        if (notSearchedEdits.length !== 0) {
          //Iterating through edits that don't match searched text and adding insertedText format
          notSearchedEdits.forEach(word => {
            quillEditor.formatText(word.index, word.wordLength, 'insertedText', false);
          });
        }
      }
    }
    indices.forEach(index => {
      if (search !== '') {
        quillEditor.formatText(index, wordLength, 'insertedText', false);
      }

      quillEditor.formatText(index, wordLength, 'highlight', remove ? false : { active: false });
    });
  };

  const formatMatchedWords = (startIndex, active) => {
    quillEditor?.formatText(startIndex, search?.length, 'highlight', {
      active: active
    });

    if (active) {
      const activeHighlight = getParentElement.current.getElementsByClassName('active-highlight');
      scrollToElementIfNeeded(activeHighlight[0], scrollRef);
    }
  };

  useEffect(() => {
    if (resultsIndices.current.length > 0) {
      setCurrentHighlight(resultsIndices.current[activeIndex]);
      if (currentHighlight !== null) {
        //remove active color and add non-active to previously selected element
        formatMatchedWords(currentHighlight, false);
      }
      //add active color
      formatMatchedWords(resultsIndices.current[activeIndex], true);
    }
  }, [activeIndex, resultsIndices.current]);

  const removeHighlight = () => {
    quillEditor?.formatText(0, quillEditor.getText().length, 'highlight', false);
  };

  useEffect(() => {
    const isArray = Array.isArray(deltaChanges);
    if (isArray) {
      deltaChanges.forEach(delta => {
        quillEditor?.updateContents(delta);
      });
    } else {
      quillEditor?.updateContents(deltaChanges);
    }
  }, [deltaChanges]);

  const isNotValidQuillEditor = () => {
    return (
      !quillEditor ||
      typeof quillEditor.getSelection !== 'function' ||
      typeof quillEditor.getBounds !== 'function'
    );
  };

  const changeIsEdited = () => {
    if (isNotValidQuillEditor()) {
      return;
    }
    const selectedText = quillEditor.getSelection();
    if (!selectedText || typeof selectedText.index !== 'number') {
      return;
    }
    const bounds = quillEditor.getBounds(selectedText.index, 1);

    if (bounds && typeof bounds.bottom === 'number') {
      setTextEditPosition(bounds.bottom);
    }
  };

  const debounceSetSelectionDimensionData = debounce(() => {
    getSectionDimensionData(handleSectionsDimensionData, setNumberingAndStyle);
  }, 200);

  const debounceSetHistory = debounce(history => {
    setHistory(history);
  }, 500);

  const extractCommentIds = () => {
    const commentIds = comments?.map(comment => comment.id);
    const editorContent = quillEditor?.getContents();
    const commentIdsFromText = editorContent?.ops.flatMap(op => op.attributes?.comment || []);
    return {
      commentCardIds: commentIds,
      commentTextIds: commentIdsFromText
    };
  };

  const removeCommentCards = () => {
    const { commentCardIds, commentTextIds } = extractCommentIds();
    const matchingItems = commentCardIds?.filter(item => !commentTextIds.includes(item));
    matchingItems.length &&
      connection
        ?.invoke(MESSAGE_TYPES.REMOVE_COMMENTS, matchingItems)
        .then(() => {
          toastMessage(
            enqueueSnackbar,
            closeSnackbar,
            t('commentsDeletedSuccess', { count: matchingItems.length }),
            'success'
          );
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
  };

  const opHasDeleteKey = delta => {
    return (
      delta.ops.length <= 2 &&
      delta.ops.some(item => Object.hasOwn(item, 'delete') && item.delete !== null)
    );
  };

  const handleOnChange = (_, delta, changeType) => {
    const range = quillEditor.getSelection();
    selection.current = range;

    if (opHasDeleteKey(delta) && comments?.length) {
      removeCommentCards();
    }

    selectionDelta.current = delta;
    if (changeType === 'user') {
      removeSuggestionsDueToManualTextChange(delta);
      removeComplexityHighlight(complexityFormats, delta, showComplexityMap, quillEditor);

      // Check if a change is related to the list or indentation.
      // If that's the case, refresh the list numbering.
      if (
        delta.ops?.some(
          op => op.attributes?.listItem !== undefined || op.attributes?.indent !== undefined
        )
      ) {
        setNumberingAndStyle(document.getElementsByTagName('list-item'));
      }

      if (search !== '') {
        // This part only executes when search is not empty, because then the highlighting can appear.
        // After adding changes to the text, the highlight is removed from the text and then searchText
        // function is called in order to set the search again and highlight appropriate words.
        removeHighlight();
        searchText(search, false);
      }

      const stringifiedDelta = stringifyCommentValues(delta);

      commentSelection.length === -1 &&
        connection?.invoke(MESSAGE_TYPES.BROADCAST_CHANGES, Number(documentId), stringifiedDelta);

      // start timers after the first text change and reset them later, on every change(textual or non-textual)
      if (
        delta.ops.some(
          o => o.insert !== undefined || o.delete !== undefined || o.attributes !== undefined
        )
      ) {
        textChanged = true;
      }

      if (textChanged) {
        resetTimers();
      }

      debounceSetHistory({ ...quillEditor.history.stack });
    }

    debounceSetSelectionDimensionData();

    // reset timeout to avoid accessing DOM tree too often (DOM access is slow on Safari)
    clearTimeout(changeTimeout);
    changeTimeout = setTimeout(changeIsEdited, 1000);
  };

  const getSelectionOffset = () => {
    const selectedText = quillEditor.getSelection();
    if (selectedText) {
      const bounds = quillEditor.getBounds(selectedText.index, 1);
      return bounds.bottom;
    }

    return 0;
  };

  const onChangeSelection = (range, source) => {
    // Editor loses selection on modal opening
    // Don't save the selection state when the modal is opened to be able to set the selection again on the modal closing
    selection.current = range;

    if (isCommentingActive && range) {
      removePreviousSelectionWhileAddingComments(commentSelection, quillEditor, commentId);
      setCommentSelection(range);
    }

    if (source === 'paste') {
      const offSet = getSelectionOffset();
      scrollToNewlyInsertedElement(scrollRef, offSet);
    }
    selection.current && setSelectedListBtn(quillEditor, selection.current);
  };

  const onKeyUpHandler = e => {
    const arrowKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
    if (e.key === 'Enter') {
      const offSet = getSelectionOffset();
      scrollToNewlyInsertedElement(scrollRef, offSet);
      checkIfBoldIsActiveOnNewListItemLine(quillEditor);
      getClickedSentenceRange();
    }

    if (isNotValidQuillEditor()) {
      return;
    }

    if (arrowKeys.includes(e.key)) {
      // Set selection because onKeyUpHandler is called before the onChangeSelection method
      // Otherwise, the old index will be used in the getClickedSentenceRange method.
      selection.current = quillEditor.getSelection();
      getClickedSentenceRange();
    }
  };

  const onEditorClick = () => {
    if (isMobile || isCommentingActive || isCommentTabSelected) {
      return undefined;
    } else {
      getClickedSentenceRange();
    }
  };

  return (
    <div
      className={classes.toolbarContainer}
      data-text-editor='editorContainer'
      onClick={onEditorClick}>
      {clickedIndicatorIndex !== -1 && (
        <ParagraphLevelSuggestionDialog
          dialogRef={dialogRef}
          clickedIndicatorIndex={clickedIndicatorIndex}
          setClickedIndicatorIndex={setClickedIndicatorIndex}
          sectionsDimensionData={sectionsDimensionData}
          isFetchingSuggestions={isFetchingSuggestions}
          sentenceSuggestions={sentenceSuggestions}
          setSentenceSuggestions={setSentenceSuggestions}
          resetTimers={resetTimers}
          quillEditor={quillEditor}
          scrollRef={scrollRef}
        />
      )}
      <ReactQuill
        readOnly={isMobile || isReconnecting || isEditingDisabled}
        ref={el => (quillRef.current = el)}
        placeholder={'Text'}
        theme={'snow'}
        modules={modules}
        formats={formats}
        onChangeSelection={onChangeSelection}
        onChange={handleOnChange}
        onKeyUp={onKeyUpHandler}
        // eslint-disable-next-line quotes
        bounds={"[data-text-editor='editorContainer']"}
      />
    </div>
  );
};

DocumentEditComponent.propTypes = {
  deltaChanges: PropTypes.array,
  resetTimers: PropTypes.func,
  handleSectionsDimensionData: PropTypes.func,
  activeIndex: PropTypes.number,
  getParentElement: PropTypes.any,
  search: PropTypes.string,
  showComplexityMap: PropTypes.bool,
  selection: PropTypes.object,
  quillRef: PropTypes.object,
  getClickedSentenceRange: PropTypes.func,
  removeSuggestionsDueToManualTextChange: PropTypes.func,
  setActiveHighlightIndex: PropTypes.func,
  scrollRef: PropTypes.object,
  clickedIndicatorIndex: PropTypes.number,
  setClickedIndicatorIndex: PropTypes.func,
  sectionsDimensionData: PropTypes.array,
  isFetchingSuggestions: PropTypes.bool,
  sentenceSuggestions: PropTypes.array,
  setSentenceSuggestions: PropTypes.func,
  dialogRef: PropTypes.object,
  isCommentingActive: PropTypes.bool,
  commentSelection: PropTypes.object,
  setCommentSelection: PropTypes.func,
  selectionDelta: PropTypes.object,
  isCommentTabSelected: PropTypes.bool,
  comments: PropTypes.array,
  commentId: PropTypes.string,
  resultsIndices: PropTypes.object
};
