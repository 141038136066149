import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import { Box, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Icons } from 'components';
import { filterDropdownOptions } from 'helpers';

import { FilterProperty } from './FilterProperty';
import { useStyles } from './FilterStyling.css';

export const FilterDropdown = ({
  selectedFilterOptions,
  setSelectedFilterOptions,
  configuration,
  page
}) => {
  const theme = useTheme();
  const [t] = useTranslation('common');

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ref = useRef(null);

  const [selectedSubFilterValue, setSelectedSubFilterValue] = useState(() => {
    const subFilterState = {};
    configuration.forEach(config => {
      subFilterState[config.name] = config.defaultSubFilterValue;
    });
    return subFilterState;
  });

  const classes = useStyles(theme);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCategory(null);
  };

  return (
    <>
      <Stack
        ref={ref}
        onClick={e => setAnchorEl(e.currentTarget)}
        className={classes.stackContainer}
        data-cy='filtersDropdown'>
        <Icons iconName={'documentFilterIcon'} />
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: theme.typography.pxToRem(16),
            fontFamily: theme.typography.fontFamilyPrimaryRegular,
            paddingLeft: '10px'
          }}>
          {t('filters')}
        </Typography>
        <Box component={Icon} icon={arrowIosDownwardFill} sx={{ ml: '15px' }} />
      </Stack>
      <Menu
        open={open}
        anchorEl={ref.current}
        onClose={handleClose}
        PaperProps={{
          sx: { width: '280px', mt: '10px' }
        }}
        MenuListProps={{
          sx: { padding: '0px' }
        }}>
        {selectedCategory ? (
          <FilterProperty
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedFilterOptions={selectedFilterOptions}
            setSelectedFilterOptions={setSelectedFilterOptions}
            subFilterValue={selectedSubFilterValue[selectedCategory.name]}
            setSelectedSubFilterValue={setSelectedSubFilterValue}
            handleClose={handleClose}
            page={page}
          />
        ) : (
          configuration.map(item => {
            if (item.permission) {
              return (
                <MenuItem
                  data-cy={`${item.name}DropdownItem`}
                  key={item.name}
                  onClick={() => {
                    setSelectedCategory(item);
                  }}
                  className={classes.filterCategories}>
                  <Grid sx={{ display: 'flex' }}>
                    <div
                      style={{
                        marginRight: item.name === filterDropdownOptions.USERS ? '15px' : '10px',
                        marginTop: '3px'
                      }}>
                      <Icons iconName={item.iconName} fill={theme.palette.primary.main} />
                    </div>
                    <Typography
                      sx={{
                        fontFamily: theme.typography.fontFamilyPrimaryRegular,
                        fontSize: theme.typography.pxToRem(16),
                        pt: '2px'
                      }}>
                      {t(item.name)}
                    </Typography>
                  </Grid>
                  <Grid sx={{ display: 'flex', alignContent: 'center' }}>
                    <Box
                      component={Icon}
                      icon={arrowIosForwardFill}
                      sx={{ width: 16, height: 16 }}
                    />
                  </Grid>
                </MenuItem>
              );
            }
          })
        )}
      </Menu>
    </>
  );
};

FilterDropdown.propTypes = {
  selectedFilterOptions: PropTypes.object,
  setSelectedFilterOptions: PropTypes.func,
  configuration: PropTypes.array,
  page: PropTypes.number
};
