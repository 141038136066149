import { useCallback, useContext, useEffect, useState, useRef } from 'react';

import {
  checkParagraphEmptyStatus,
  MESSAGE_TYPES,
  suggestionActionTypesEnum,
  suggestionTargetTypesEnum,
  checkRequestLoggingToConsole,
  mixpanel,
  updateSuggestionsOnChange,
  detectMouseDownEvent
} from 'helpers';
import { useMemoizedState } from 'hooks';
import { UserContext, WebSocketConnectionContext } from 'providers';

export const useSuggestions = ({ quillEditor }) => {
  const [sentenceSuggestions, setSentenceSuggestions] = useMemoizedState([]);
  const [isSectionIndicatorClicked, setIsSectionIndicatorClicked] = useState(false);
  const [isSuggestionDivClicked, setIsSuggestionDivClicked] = useState(false);
  const [removeSuggestions, setRemoveSuggestions] = useState(false);
  const [clickedWord, setClickedWord] = useState({});
  const isMLDataNotPresentRef = useRef(false);
  const suggestionsListRef = useRef();
  const sentenceRange = useRef({ id: 0, startIndex: -1, length: 0 });
  const selection = useRef({ index: -1, length: 0 });

  const suggestionFormats = ['word_suggestion', 'sentence_suggestion', 'paragraph_suggestion'];

  const webSocketConnectionState = {
    CONNECTED: 'Connected'
  };

  const editorHasFocus = quillEditor?.hasFocus();

  const { user } = useContext(UserContext);
  const {
    connection,
    previouslyClickedSection,
    setPreviouslyClickedSection,
    sections,
    sectionsInfo,
    setIsFetchingReplacements,
    setIsFetchingSuggestions,
    id,
    setSections,
    cleanup,
    setConnection,
    setOverallReadabilityData
  } = useContext(WebSocketConnectionContext);

  const underlineWordSuggestions = () => {
    const processedTargetIds = [];
    sentenceSuggestions
      .filter(suggestion => suggestion.targetType === suggestionTargetTypesEnum.WORD)
      .forEach(suggestion => {
        quillEditor?.formatText(
          suggestion.suggestionRange.startIndex,
          suggestion.suggestionRange.length,
          'word_suggestion',
          true
        );
        if (
          selection?.current?.index >= suggestion.suggestionRange.startIndex &&
          selection?.current?.index <= suggestion.suggestionRange.endIndex &&
          !processedTargetIds.includes(suggestion.targetId)
        ) {
          processedTargetIds.push(suggestion.targetId);
          setClickedWord(suggestion);
        }
      });
  };

  const paragraphIndex = quillEditor?.getSelection()?.index;
  const isEmptyParagraph = checkParagraphEmptyStatus(sectionsInfo, paragraphIndex);

  const filterOutCertainSuggestions = suggestions => {
    const filteredSuggestions = suggestions.filter(
      sugg =>
        sugg.action !== suggestionActionTypesEnum.REMOVE_COMPLEX_PHRASE &&
        sugg.action !== suggestionActionTypesEnum.LONG_SENTENCE
    );
    setSentenceSuggestions(filteredSuggestions);
  };

  const getSentenceSuggestions = () => {
    const connectionState = connection?.connection._connectionState;
    if (selection?.current && connectionState === webSocketConnectionState.CONNECTED) {
      if (isEmptyParagraph) {
        setSentenceSuggestions([]);
      } else {
        setIsFetchingSuggestions(true);
        setIsFetchingReplacements(false);
        connection
          ?.invoke(MESSAGE_TYPES.GET_SENTENCE_SUGGESTIONS, Number(id), selection.current.index)
          .then(resp => {
            if (resp.suggestions !== null) {
              setIsFetchingSuggestions(false);
              filterOutCertainSuggestions(resp.suggestions);
            }
            checkRequestLoggingToConsole(user.permissions, resp.requestIM) &&
              // eslint-disable-next-line no-console
              console.log(
                `${resp.requestIM.requestType} request ${resp.requestIM.requestId} for document ${id} has been sent. Request cacheId is ${resp.requestIM.cacheId}. Model version is ${resp.requestIM.modelVersion}.`
              );
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }
  };

  const highlightParagraph = sectionId => {
    connection
      ?.invoke(MESSAGE_TYPES.GET_CLICKED_SECTION_RANGE, Number(id), Number(sectionId))
      .then(resp => {
        if (previouslyClickedSection) {
          quillEditor.formatText(
            previouslyClickedSection.startIndex,
            previouslyClickedSection.endIndex - previouslyClickedSection.startIndex,
            'paragraph_suggestion',
            false
          );
        }
        if (resp) {
          quillEditor.formatText(
            resp.startIndex,
            resp.endIndex - resp.startIndex,
            'paragraph_suggestion',
            true
          );
          setPreviouslyClickedSection(resp);
        }
      });
  };

  const getSectionSuggestions = sectionId => {
    setIsSectionIndicatorClicked(true);
    setIsFetchingSuggestions(true);
    highlightParagraph(sectionId);
    connection
      ?.invoke(MESSAGE_TYPES.GET_SECTION_SIMPLIFICATIONS, Number(id), Number(sectionId))
      .then(resp => {
        if (resp.simplifications) {
          setIsFetchingSuggestions(false);
          setSentenceSuggestions(resp.simplifications);
        }
        isMLDataNotPresentRef.current = resp.mlDataNotPresent;
        checkRequestLoggingToConsole(user.permissions, resp.requestIM) &&
          // eslint-disable-next-line no-console
          console.log(
            `${resp.requestIM.requestType} request ${resp.requestIM.requestId} for section ${resp.sectionId} of document ${id} has been sent. Request cacheId is ${resp.requestIM.cacheId}. Model version is ${resp.requestIM.modelVersion}.`
          );
      });
    mixpanel.track('Get paragraph-level suggestions', {
      // eslint-disable-next-line prettier/prettier
      Document: id
    });
  };

  const handleClickOnSuggestions = e => {
    if (suggestionsListRef.current?.contains(e.target)) {
      e.preventDefault();
      setIsSuggestionDivClicked(true);
    } else {
      setIsSuggestionDivClicked(false);
    }
    setRemoveSuggestions(false);
  };

  const removeAllHighlighting = () => {
    suggestionFormats.forEach(format => {
      quillEditor?.formatText(0, quillEditor.getText().length, format, false);
    });
  };

  const removeSuggestionsDueToManualTextChange = delta => {
    if (!removeSuggestions) {
      // Check if all text changes don't contain a newline character
      // If that's the case, update the underlining/highlighting of sentence suggestions
      // Otherwise there is inserted newline character, and (split) sentence suggestions are not valid anymore.
      if (
        delta?.ops.every(
          o =>
            o.insert === undefined ||
            (typeof o.insert === 'string' && !o.insert?.includes('\n') && !o.insert?.includes('.'))
        )
      ) {
        const newSuggestions = updateSuggestionsOnChange(sentenceSuggestions, delta);
        setSentenceSuggestions(newSuggestions);
      } else {
        setRemoveSuggestions(true);
        setSentenceSuggestions([]);

        removeAllHighlighting();
      }
    }
  };

  const getClickedSentenceRange = useCallback(keepSuggestions => {
    if (selection?.current) {
      connection
        ?.invoke(MESSAGE_TYPES.GET_CLICKED_SENTENCE_RANGE, Number(id), selection.current.index)
        .then(resp => {
          // Check is selected sentence different from the current
          if (
            keepSuggestions ||
            resp === null ||
            resp.id === 0 ||
            resp.id !== sentenceRange.current.id
          ) {
            setIsSectionIndicatorClicked(false);
            removeAllHighlighting();
            setPreviouslyClickedSection();

            if (resp !== null) {
              quillEditor?.formatText(resp.startIndex, resp.length, 'sentence_suggestion', true);
              sentenceRange.current = {
                id: resp.id,
                startIndex: resp.startIndex,
                length: resp.length
              };
              !keepSuggestions && getSentenceSuggestions();
            }
          } else {
            handleClickedSuggestion();
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  });

  const handleClickedSuggestion = () => {
    if (selection.current.length === 0) {
      const clickedSuggestion = sentenceSuggestions
        ?.filter(suggestion => suggestion.targetType === suggestionTargetTypesEnum.WORD)
        .find(
          ss =>
            selection.current.index >= ss.suggestionRange.startIndex &&
            selection.current.index <= ss.suggestionRange.endIndex
        );
      if (clickedSuggestion) {
        setClickedWord(clickedSuggestion);
      } else {
        // If there is no clicked suggestion, check if there is the previous one to remove the highlight from it
        clickedWord?.suggestionRange &&
          quillEditor.formatText(
            clickedWord.suggestionRange.startIndex,
            clickedWord.suggestionRange?.length,
            'word_suggestion',
            { active: false }
          );
        setClickedWord({});
      }
    }
  };

  useEffect(() => {
    if (sentenceSuggestions && selection.current) {
      quillEditor?.formatText(0, quillEditor.getText().length, 'word_suggestion', false);
      underlineWordSuggestions();
    }
  }, [sentenceSuggestions]);

  useEffect(() => {
    connection?.on(MESSAGE_TYPES.RECEIVE_SENTENCE_SUGGESTIONS, suggestionsResult => {
      if (
        selection.current.index >= suggestionsResult.startIndex &&
        selection.current.index <= suggestionsResult.endIndex
      ) {
        setIsFetchingSuggestions(false);
        filterOutCertainSuggestions(suggestionsResult.suggestions);
        quillEditor.formatText(
          suggestionsResult.startIndex,
          suggestionsResult.endIndex - suggestionsResult.startIndex,
          'sentence_suggestion',
          true
        );
      }
    });
    detectMouseDownEvent(handleClickOnSuggestions);

    return () => {
      cleanup();
      setConnection(null);
      setOverallReadabilityData(null);
      setSections(null);
    };
  }, []);

  useEffect(() => {
    // Remove previously defined methods for the RECEIVE_SECTION_SIMPLIFICATIONS message
    // Otherwise, there would be one method for each previouslyClickedSection state that would occur on RECEIVE_SECTION_SIMPLIFICATIONS
    connection?.off(MESSAGE_TYPES.RECEIVE_SECTION_SIMPLIFICATIONS);

    connection?.on(
      MESSAGE_TYPES.RECEIVE_SECTION_SIMPLIFICATIONS,
      (sectionSimplifications, mlDataNotPresentResult) => {
        // Check if the response is for the last selected section
        if (previouslyClickedSection.id === sectionSimplifications.sectionId) {
          if (mlDataNotPresentResult) {
            setSentenceSuggestions([]);
            setIsFetchingSuggestions(false);
          } else {
            setSentenceSuggestions(sectionSimplifications.simplifications);
            setIsFetchingSuggestions(false);
          }
        }
      }
    );
  }, [previouslyClickedSection]);

  useEffect(() => {
    if (sections) {
      // Resetting sentence highlight and suggestions underlining after setting the editor content(sections)
      quillEditor?.formatText(
        sentenceRange.current.startIndex,
        sentenceRange.current.length,
        'sentence_suggestion',
        true
      );
      underlineWordSuggestions();
    }

    // Resetting section highlight after setting the editor content(sections)
    if (previouslyClickedSection) {
      highlightParagraph(previouslyClickedSection.id);
    }
  }, [sections]);

  useEffect(() => {
    if (!editorHasFocus) {
      // When user clicks on a suggestion (word or sentence), it becomes active.
      // So, if user then clicks somewhere outside of the editor, that suggestion should no longer be active and active classes should be removed from it.
      removeAllHighlighting();
      selection.current = { index: -1, length: 0 };
      sentenceRange.current = { id: 0, startIndex: -1, length: 0 };
      setSentenceSuggestions([]);
    }
  }, [editorHasFocus]);

  return {
    selection,
    clickedWord,
    setClickedWord,
    sentenceSuggestions,
    setSentenceSuggestions,
    isMLDataNotPresentRef,
    isSectionIndicatorClicked,
    removeSuggestions,
    setRemoveSuggestions,
    isSuggestionDivClicked,
    suggestionsListRef,
    getSectionSuggestions,
    underlineWordSuggestions,
    removeSuggestionsDueToManualTextChange,
    getClickedSentenceRange,
    editorHasFocus
  };
};
