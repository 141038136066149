import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { AccountSwitchDialog } from './AccountSwitch/AccountSwitchDialog';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}));

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.gray.lighter
}));

export const DashboardLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [isOpen, setIsOpen] = useState(true);
  const [isSwitchAccountDialogOpen, setIsSwitchAccountDialogOpen] = useState(false);
  const { pathname } = useLocation();
  const isDocumentsReadabilityPage =
    pathname.includes('documents/') && !pathname.includes('documents/create');

  const setHorizontalPadding = () => {
    return isMobile ? '1rem' : setPaddingForDocument();
  };

  const setPaddingForDocument = () => {
    return isDocumentsReadabilityPage ? '0' : '2rem !important';
  };

  return (
    <RootStyle>
      {isMobile && !isDocumentsReadabilityPage && (
        <DashboardNavbar onOpenSidebar={() => setIsOpen(true)} />
      )}
      <DashboardSidebar
        isOpenSidebar={isOpen}
        onCloseSidebar={() => setIsOpen(false)}
        setIsSwitchAccountDialogOpen={setIsSwitchAccountDialogOpen}
      />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          paddingLeft: isMobile ? '1rem' : setHorizontalPadding(),
          paddingRight: isMobile ? '1rem' : setHorizontalPadding(),
          backgroundColor: theme.palette.gray.white
        }}>
        <AccountSwitchDialog
          isOpen={isSwitchAccountDialogOpen}
          setIsOpen={setIsSwitchAccountDialogOpen}
        />
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
};
