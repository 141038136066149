import React from 'react';

import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { getFormattedSelectedFilterOptions, mergeSelectedFilterOptions } from 'helpers';

import { SelectedFilterDisplayChip } from './SelectedFilterDisplayChip';

export const SelectedFiltersDisplay = ({
  isAtLeastOneArrayNotEmpty,
  selectedFilterOptions,
  handleDelete
}) => {
  const formattedSelectedFilterOptions = getFormattedSelectedFilterOptions(selectedFilterOptions);
  const mergedArray = mergeSelectedFilterOptions(formattedSelectedFilterOptions);

  return (
    <Stack
      direction='row'
      sx={{
        maxWidth: '100% !important',
        display: 'flex',
        flexWrap: 'wrap',
        '&.MuiContainer-root': {
          pl: 0
        },
        mr: 1,
        ml: 0
      }}>
      {isAtLeastOneArrayNotEmpty &&
        mergedArray.map(item => (
          <SelectedFilterDisplayChip
            key={`${item.category}_${item.filterOption}_${item.value.id}`}
            handleDelete={handleDelete}
            item={item}
          />
        ))}
    </Stack>
  );
};

SelectedFiltersDisplay.propTypes = {
  isAtLeastOneArrayNotEmpty: PropTypes.bool,
  selectedFilterOptions: PropTypes.object,
  handleDelete: PropTypes.func
};
