import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Scrollbar, Icons } from 'components';

import { CommonComplexWord } from './CommonComplexWord';
import { MetricsRating } from './MetricsRating';

export const KeyIssues = ({ intelligibilityData }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('xlm'));

  const displayKeyIssues = () => {
    const keyIssues = intelligibilityData?.textMetrics?.keyIssues;
    const sortedKeyIssues = keyIssues?.sort((a, b) => a.metricName.localeCompare(b.metricName));
    return keyIssues?.length === 0 ? (
      <Grid sx={{ mt: '15px', display: 'flex', alignItems: 'center' }}>
        <Icons iconName={'checkedBlueIcon'} />
        <Typography
          sx={{
            fontSize: theme.typography.pxToRem(12),
            fontFamily: theme.typography.fontFamilyPrimaryRegular,
            color: theme.palette.gray.main,
            ml: '15px',
            mt: '1px'
          }}>
          {t('documentWithoutKeyIssues')}
        </Typography>
      </Grid>
    ) : (
      sortedKeyIssues?.map(item => {
        return (
          <MetricsRating
            key={uuidv4()}
            metricType={item.metricName}
            writingStyle={item.description}
            isKeyIssues
          />
        );
      })
    );
  };

  return (
    <>
      <Typography
        variant='subtitle2'
        sx={{
          color: theme.palette.gray.dark,
          fontFamily: theme.typography.fontFamilyPrimarySemibold,
          pt: '10px'
        }}>
        {t('keyIssues')}
      </Typography>
      <Scrollbar sx={{ overflow: 'overlay', maxHeight: isBigScreen ? 220 : 140, zIndex: 1 }}>
        {displayKeyIssues()}
      </Scrollbar>
      <Divider sx={{ mt: '25px' }} />
      <CommonComplexWord intelligibilityData={intelligibilityData} />
    </>
  );
};

KeyIssues.propTypes = {
  intelligibilityData: PropTypes.object
};
