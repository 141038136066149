import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  paper: props => ({
    border: `1px solid ${props.palette.gray.light}`,
    borderRadius: '10px',
    boxShadow: `0 2px 4px 0 ${props.palette.gray.light}`,
    marginTop: 0,
    margin: 0,
    width: '100%'
  }),
  closeIcon: props => ({
    width: '20px',
    height: '20px',
    cursor: 'pointer',
    color: props.palette.gray.main,
    marginRight: '5px'
  }),
  simplifyText: props => ({
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    fontWeight: 500,
    fontSize: props.typography.pxToRem(14),
    color: props.palette.primary.main,
    paddingLeft: '10px'
  }),
  simplifications: props => ({
    width: '95%',
    fontFamily: props.typography.fontEditorPrimary,
    fontWeight: props.typography.fontWeightRegular,
    fontSize: props.typography.pxToRem(14),
    color: props.palette.primary.main
  }),

  accordionSummary: {
    minHeight: '100px',
    padding: '15px',
    alignItems: 'flex-start',
    '&.Mui-expanded': {
      minHeight: '20px'
    },
    '& .MuiAccordionSummary-content': {
      marginTop: 0,
      marginBottom: 0,
      '&.Mui-expanded': {
        marginTop: 0,
        marginBottom: 0
      }
    }
  },
  accordionDetails: {
    padding: 0,
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  simplificationActionsButton: {
    padding: 0,
    width: '32px',
    height: '32px'
  }
}));
