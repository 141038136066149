const INITIAL_SUGGESTIONS_FILTER_OPTIONS = {
  category: [],
  impact: []
};

const initialState = {
  isReconnecting: false,
  isFetchingDocumentSuggestions: true,
  isFetchingReplacements: true,
  isFetchingSelectedTextSimplifications: false,
  isParagraphDialogOpen: false,
  isRephraseSelected: false,
  previouslyClickedSection: null,
  undoRedoStack: { undo: [], redo: [] },
  selectedMode: '2',
  selectedSuggestionFilterOptions: INITIAL_SUGGESTIONS_FILTER_OPTIONS,
  filteredDocumentSuggestions: [],
  selectedSimplificationCard: null,
  isPendingReload: false,
  pendingSuggestions: [],
  selectedSuggestionIds: []
};

export const editorDataStore = set => ({
  ...initialState,
  setIsReconnecting: value => set({ isReconnecting: value }),
  setIsFetchingDocumentSuggestions: value => set({ isFetchingDocumentSuggestions: value }),
  setIsFetchingReplacements: value => set({ isFetchingReplacements: value }),
  setIsFetchingSelectedTextSimplifications: value =>
    set({ isFetchingSelectedTextSimplifications: value }),
  setIsParagraphDialogOpen: value => set({ isParagraphDialogOpen: value }),
  setIsRephraseSelected: value => set({ isRephraseSelected: value }),
  setPreviouslyClickedSection: value => set({ previouslyClickedSection: value }),
  setUndoRedoStack: value => set({ undoRedoStack: value }),
  setSelectedMode: value => set({ selectedMode: value }),
  setSelectedSimplificationCard: value => set({ selectedSimplificationCard: value }),
  setIsPendingReload: value => set({ isPendingReload: value }),
  setPendingSuggestions: value => set({ pendingSuggestions: value }),
  setSelectedSuggestionIds: value => set({ selectedSuggestionIds: value }),
  resetEditorDataStore: () => set(initialState)
});
