import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { ParagraphLevelSuggestionsDialogTitle, Scrollbar } from 'components';
import { SimplificationLoader } from 'components/SimplificationLoader/SimplificationLoader';
import { MESSAGE_TYPES } from 'helpers';
import { DocumentStateContext } from 'providers';
import { useStoreFields } from 'stores';

import { useStyles } from './ParagraphLevelSuggestionsDialog.css';
import { SimplificationItem } from '../SimplificationItem/SimplificationItem';

export const ParagraphLevelSuggestionDialog = ({
  isFetchingSuggestions,
  sentenceSuggestions,
  setSentenceSuggestions,
  quillEditor,
  dialogRef,
  scrollRef,
  setSelectedText,
  selectedText,
  isRephraseSelected,
  suggestion,
  setExpandedAccordion,
  setIsDialogOpen
}) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const selectedIndex = selectedText.index + selectedText.length;

  const container = document.getElementById('editorContainer');
  const rect = container?.getBoundingClientRect();
  const rectContainerWidth = rect?.width + rect?.width * 0.08;
  const DIALOG_MAX_WIDTH = rectContainerWidth <= 750 ? rect?.width : 750;

  const { connection } = useContext(DocumentStateContext);
  const classes = useStyles(theme);
  const { selectedSimplificationCard, setSelectedSimplificationCard } = useStoreFields([
    'selectedSimplificationCard',
    'setSelectedSimplificationCard'
  ]);

  useEffect(() => {
    if (!isRephraseSelected) {
      handleDialogClose();
    }
  }, [isRephraseSelected]);

  const simplificationContent = () => {
    return (
      <Scrollbar sx={{ maxHeight: '300px' }}>
        <DialogActions
          disableSpacing
          sx={{
            p: 0,
            display: 'flex',
            flexDirection: 'column'
          }}>
          {sentenceSuggestions?.map((sentenceSuggestion, index) => {
            return (
              <SimplificationItem
                key={sentenceSuggestion.simplificationId}
                index={index}
                sentenceSuggestion={sentenceSuggestion}
                sentenceSuggestions={sentenceSuggestions}
                setSentenceSuggestions={setSentenceSuggestions}
                quillEditor={quillEditor}
                dialogRef={dialogRef}
                scrollRef={scrollRef}
                endIndex={selectedIndex}
                startIndex={selectedText.index}
                selectedText={quillEditor?.getText(
                  selectedText.index,
                  selectedIndex - selectedText.index
                )}
                setSelectedText={setSelectedText}
                suggestion={suggestion}
                setExpandedAccordion={setExpandedAccordion}
              />
            );
          })}
        </DialogActions>
      </Scrollbar>
    );
  };

  const getDialogPosition = () => {
    const startBounds = quillEditor?.getBounds(selectedText.index);
    const endBounds = quillEditor?.getBounds(selectedIndex);

    let left;
    let top;
    if (rectContainerWidth && startBounds.bottom === endBounds.bottom) {
      if (rectContainerWidth - DIALOG_MAX_WIDTH >= startBounds.left) {
        left = startBounds.left;
      } else {
        left = rectContainerWidth / 2 - DIALOG_MAX_WIDTH / 2;
      }

      top = startBounds.top + startBounds.height;
    } else {
      left = 0;
      top = endBounds.top + endBounds.height;
    }

    return { top: top, left: left };
  };

  const handleDialogClose = () => {
    quillEditor.formatText(selectedText.index, selectedText.length, 'paragraph_suggestion', false);

    if (!selectedSimplificationCard) {
      setSelectedText(null);
    }

    if (selectedSimplificationCard) {
      setSelectedSimplificationCard(null);
      setIsDialogOpen(false);
    }

    connection?.invoke(MESSAGE_TYPES.CLOSE_SIMPLIFICATIONS);
  };

  return (
    <Dialog
      data-testid='paragraphLevelSuggestionsDialog'
      slotProps={{
        backdrop: {
          style: {
            opacity: 0
          }
        }
      }}
      sx={{
        position: 'absolute',
        '& .MuiDialog-container': {
          position: 'relative',
          top: getDialogPosition().top,
          left: `${getDialogPosition().left}px`,
          height: 'auto',
          width: isFetchingSuggestions ? DIALOG_MAX_WIDTH : 'auto',
          minWidth: '300px',
          maxWidth: DIALOG_MAX_WIDTH
        }
      }}
      fullWidth={isFetchingSuggestions}
      container={() => document.getElementById('popupParentContainer')}
      maxWidth={DIALOG_MAX_WIDTH}
      PaperProps={{ className: classes.paper }}
      open={selectedText?.length > 1}
      onClick={e => e.stopPropagation()}
      onClose={handleDialogClose}>
      <ParagraphLevelSuggestionsDialogTitle
        handleDialogClose={handleDialogClose}
        title='rephraseText'
      />
      <DialogContent
        ref={dialogRef}
        sx={{
          '&.MuiDialogContent-root': {
            padding: 0
          }
        }}>
        {isFetchingSuggestions ? (
          <SimplificationLoader height={30} my={6} fontSize={16} />
        ) : (
          simplificationContent()
        )}
        {!isFetchingSuggestions && sentenceSuggestions?.length === 0 && (
          <Box
            data-testid='noSuggestionsBox'
            sx={{
              height: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Typography
              data-testid='noSuggestionsText'
              variant='body3'
              sx={{
                fontFamily: theme.typography.fontFamilyPrimaryRegular,
                fontSize: theme.typography.pxToRem(16)
              }}>
              {t('noReplacementsAvailable')}
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

ParagraphLevelSuggestionDialog.propTypes = {
  isFetchingSuggestions: PropTypes.bool,
  sentenceSuggestions: PropTypes.array,
  setSentenceSuggestions: PropTypes.func,
  quillEditor: PropTypes.object,
  dialogRef: PropTypes.object,
  scrollRef: PropTypes.object,
  setSelectedText: PropTypes.func,
  selectedText: PropTypes.object,
  isRephraseSelected: PropTypes.bool,
  suggestion: PropTypes.object,
  setExpandedAccordion: PropTypes.func,
  setIsDialogOpen: PropTypes.func
};
